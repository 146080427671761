
import { footerLinks } from '../../../fc/Constants/config-footer'
export default {
    name: 'FooterLinkList',
    computed: {
        footerLinks() { return footerLinks(this) }
    },
    methods: {
        isExternalUrl(data) {
            if (data.type && data.type === 'url') {
                return true
            } else {
                return false
            }
        }
    }
}
